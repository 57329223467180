import React from 'react'
import classNames from 'classnames'

import s from './NotFound.module.sass'
import {
    Container,
    Title,
    Button
} from '../../ui-kit'
import NotFoundImage from '../../icons/not-found-page/not-found.svg';

export default function NotFound() {
    return (
        <section className={s.notFound}>
            <Container size="fluid">
                <div className={s.img}><NotFoundImage/></div>
                <Title>Что-то пошло не так</Title>
                <Button className={classNames("green", s.button)} onClick={() => {window.history.back();}}>Вернуться назад</Button>
            </Container>
        </section>
    )
}